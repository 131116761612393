<template>
  <div class="lg-top">
    <div class="nav">
      <el-row>
        <el-col :xs="20" :sm="9" :md="7" :lg="10" class="nav-logo">
          <img src="../../assets/imgs/logo.png" />
          <span class="logo-title">成都艾启乐信息技术有限公司</span>
        </el-col>
        <el-col :sm="15" :md="17" :lg="14" class="hidden-sm-and-down">
          <div class="nav-right">
            <div
              v-for="(item, index) in listMenu"
              :key="index"
              :class="active == index ? 'menuActive' : 'menuNotActive'"
              @click="clickRouterPush(item.url, index)"
            >
              <span :class="item.icon"></span> {{ item.name }}
            </div>
          </div>
        </el-col>
        <el-col :xs="4" :sm="15" :md="17" class="hidden-md-and-up">
          <div class="yd-right" @click="menuIsFold">
            <i
              :class="!ydMenuIsShow ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
            ></i>
          </div>
          <div v-show="ydMenuIsShow" class="yd-popup" @click="menuIsFold">
            <ul>
              <li
                v-for="(item, index) in listMenu"
                :key="index"
                :class="active == index ? 'ydMenuActive' : ''"
                @click="clickRouterPush(item.url, index)"
              >
                <span :class="item.icon"></span>
                {{ item.name }}
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/tools";
import Store from "@/store";
export default {
  name: "TopBar",
  data: function () {
    return {
      ydMenuIsShow: false, //移动端菜单详情伸开、关闭
      top: "0",
      // 滚动前，滚动条距文档顶部的距离
      oldScrollTop: 0,
      timer: null,
      isMobile: false,
      listMenu: [
        {
          name: "首页",
          url: "/",
          icon: "iconfont icon-gongsi",
        },
        {
          name: "业务介绍",
          url: "BusinessIntroduce",
          icon: "iconfont icon-tuandui1",
        },
        {
          name: "产品中心",
          url: "ProductShow",
          icon: "iconfont icon-fuwu_o",
        },
        {
          name: "新闻动态",
          url: "NewsCenter",
          icon: "iconfont icon-xinwen",
        },
        {
          name: "关于我们",
          url: "ContactMe",
          icon: "iconfont icon-lianxi1",
        },
      ],
    };
  },
  mounted() {
    this.isMobile = isMobile();
    // this.windowScroll();
  },
  methods: {
    // 移动端菜单详情伸开、关闭
    menuIsFold() {
      this.ydMenuIsShow = !this.ydMenuIsShow;
    },
    // 监听滚动条
    windowScroll() {
      window.addEventListener("scroll", this.scroll, true);
    },
    // 滚动条滚动事件
    scroll() {
      //防止事件过于频繁触发，设置定时器
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        if (scrollTop < 100) {
          this.top = "0";
          this.oldScrollTop = scrollTop;
          return;
        }
        if (scrollTop > this.oldScrollTop) {
          // 向下滚动
          this.top = "-70px";
        } else {
          // 向上滚动
          this.top = "0";
        }
        this.oldScrollTop = scrollTop;
      }, 100);
    },
    clickRouterPush(url, index) {
      this.$router
        .push({
          path: url,
        })
        .then(() => {
          this.$nextTick(() => {
            Store.commit("ChangeActive", index); // 改变导航栏选中状态
          });
        });
    },
    // 伸缩 移动端菜单详情
    OpenFoldMenu() {
      this.xsMenuIsShow = !this.xsMenuIsShow;
    },
  },
  computed: {
    active() {
      return Store.state.Active;
    },
  },
};
</script>

<style scoped>
.lg-top {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.09);
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10000;
  font-size: 1.9rem;
}
.nav {
  height: 6rem;
  line-height: 6rem;
}
.nav img {
  width: 4rem;
  height: 4rem;
  vertical-align: middle;
  margin-right: 1rem;
}
.nav-right span {
  font-size: 3.5rem;
  vertical-align: bottom;
}
.nav-right {
  /* flex: 1; */
  display: flex;
}

.menuNotActive {
  padding: 0 30px;
  transition: all 0.5s;
}

.menuActive {
  padding: 0 30px;
  cursor: pointer;
  color: #fff;
  background: #1db3b0;
}

.menuNotActive:hover {
  color: #1db3b0;
  cursor: pointer;
  transform: scale(1.2);
}
/*移动端*/

.yd-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}
.yd-right > i {
  font-size: 3rem;
  color: #b7c7d7;
  line-height: 6rem;
}
/* 弹窗 */
.yd-popup {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
}
.yd-popup > ul {
  padding-top: 6rem;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background: #fff;
  z-index: 1001;
  animation: MenuDetailContentAnimation 0.3s linear;
}
.yd-popup > ul > li {
  height: 4rem;
  line-height: 4rem;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  margin: 0 1.5rem;
}
.ydMenuActive {
  color: #1db3b0 !important;
  border-bottom: 2px solid #1db3b0;
}
@keyframes MenuDetailContentAnimation {
  0% {
    transform: translateX(12rem);
  }
  100% {
    transform: translateX(0);
  }
}
.nav-logo {
  text-align: center;
}

/* 最大屏幕尺寸为1500px */
@media screen and (max-width: 1500px) {
  .lg-top {
    font-size: 1.5rem;
  }
  .nav-right span {
    font-size: 1.5rem;
  }
  .nav-left {
    width: auto;
    padding-left: 0;
  }
}

/* 平板屏幕 */
/* 超小屏幕 */
@media screen and (max-width: 992px) {
  .lg-top {
    font-size: 1.2rem;
  }
  .nav-right span {
    font-size: 1.5rem;
  }
  .logo-title {
    font-size: 1.4rem;
  }
  .nav-logo {
    text-align: left;
    padding-left: 2rem;
  }
}
</style>
