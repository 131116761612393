<template>
  <div class="footer-container">
    <div class="footer">
      <div class="container">
        <div class="middle item">
          <ul>
            <li>
              <h1 style="font-weight: 100">做软件找我们</h1>
            </li>
            <li>
              <p>具备良好的品牌</p>
            </li>
            <li>
              <p>360度全方位品牌店铺服务</p>
            </li>
            <li>
              <p>张力、更快速、更大规模、更低成本!</p>
            </li>
          </ul>
        </div>
        <div class="item">
          <ul>
            <li>
              <p>
                <i class="iconfont icon-huafei"></i> 电话：
                <a href="tel:18200223312">18200223312</a>
              </p>
            </li>
            <li>
              <p><i class="iconfont icon-youxiang"></i> 邮箱：gg.cool@qq.com</p>
            </li>
            <li>
              <p>
                <i class="iconfont icon-weizhi"></i>
                地址：四川省成都市天府新区华阳街道天府大道南段888号附8号
              </p>
            </li>
          </ul>
        </div>
        <div class="item">
          <h1 style="font-weight: 100">扫码添加微信</h1>
          <img src="../../assets/imgs/contactMe/weixin.png" class="weixin" />
        </div>
      </div>
      <div class="bot-line"></div>
      <p class="banquan">
        Copyright 2019-2025 艾启乐信息技术有限公司 版权所有
        <a href="https://beian.miit.gov.cn" target="_blank"
          >备案号: 蜀ICP备2023019627号</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterAddress",
  data() {
    return {};
  },
};
</script>

<style scoped>
.footer {
  /* height: 20rem;
  border-bottom: 1px solid #c5b6b6; */
  height: 32rem;
  background: url("../../assets/imgs/footer/footer.jpg") no-repeat;
  background-size: 100% 100%;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  flex-wrap: wrap;
  margin-top: 5rem;
  color: #e4e4e4;
  width: 100%;
  max-width: 120rem;
  margin: auto;
}
a {
  color: #e4e4e4;
  /* 下划线 */
  text-decoration: underline;
}
.container > .item {
  margin: 0 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: calc(100% / 3 - 2rem);
}

.container > div:last-child {
  padding-left: 12rem;
}
.bot-line {
  /* 两边渐变的白线 */
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #000000, #efdede, #000);
}
.weixin {
  width: 12rem;
  height: 12rem;
  margin-top: 1rem;
}
.middle > div,
.saoma-weixin {
  margin-top: 1rem;
  font-size: 1.2rem;
  line-height: 1.5;
}
.banquan {
  font-size: 1.4rem;
  color: #e4e4e4;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .footer {
    height: auto;
    background: rgb(0 0 0 / 60%);
  }
  .banquan {
    margin: 0;
    padding: 0 2rem 2rem 2rem;
    font-size: 1.3rem;
  }
  .container {
    flex-direction: column;
  }
  .container > .item {
    width: 100%;
    border-bottom: 1px solid #c5b6b6;
  }
  .container > .item:last-child {
    padding-left: 0;
    padding-bottom: 2rem;
  }
  .bot-line {
    display: none;
  }
}
</style>
