<template>
  <div id="app">
    <top-bar></top-bar>
    <!-- 给路由添加过渡效果 -->
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <top-ping></top-ping>
  </div>
</template>
<script>
import TopBar from "@/components/TopBar";
import TopPing from "@/components/TopPing";
export default {
  name: "app",
  components: {
    TopBar,
    TopPing,
  },
};
</script>
<style>
:root {
  --them-color: #1db3b0;
  --primary-color: #ff0000;
  --secondary-color: #e3e3e3;
  --text-color-dark: #333;
  --text-color-light: #fff;
  --text-color-gray: #8b8b8b;
  /* 遮罩层 */
  --mask-color: rgba(0, 0, 0, 0.5);
}
#app {
}
.fade-enter,
.fade-leave-to {
  opacity: 0; /*透明度*/
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}
html {
  font-size: 62.5%;
}
@media screen and (min-width: 768px) {
  p {
    font-size: 1.4rem;
    line-height: 2;
  }
}
@media screen and (max-width: 768px) {
  p {
    font-size: 1.5rem;
    line-height: 2;
  }
}
</style>
