import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/iconfont/iconfont.css";
import "@/assets/css/rest.css";
import "./lib/element";
import "./lib/vant";
Vue.config.productionTip = false;
// 注册全局组件footerAddress
import Footer from "@/components/FooterAddress";
Vue.component("FooterAddress", Footer);
// 引入滚动动画
import scrollReveal from "scrollreveal";

Vue.prototype.$scrollReveal = (className, origin) => {
  scrollReveal().reveal(className, {
    duration: 800,
    distance: "500px",
    easing: "ease-in-out",
    origin: origin,
    mobile: false,
    // reset: true,
    // 防止出现滚动条
    viewFactor: 0.9,
  });
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
