import Vue from "vue";
import VueRouter from "vue-router";
const Index = () =>
  import(/* webpackChunkName: "Index" */ "@/views/CompanyIntroduce");
//业务介绍
const Business = () =>
  import(/* webpackChunkName: "Business" */ "@/views/BusinessIntroduce");
//产品介绍
const Product = () =>
  import(/* webpackChunkName: "Product" */ "@/views/ProductShow");
//新闻中心
const News = () => import(/* webpackChunkName: "News" */ "@/views/NewsCenter");
//关于我们
const Contact = () =>
  import(/* webpackChunkName: "Contact" */ "@/views/ContactMe");
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  //业务介绍
  {
    path: "/BusinessIntroduce",
    name: "BusinessIntroduce",
    component: Business,
  },
  //产品介绍
  {
    path: "/ProductShow",
    name: "ProductShow",
    component: Product,
  },
  //新闻中心
  {
    path: "/NewsCenter",
    name: "NewsCenter",
    component: News,
  },
  //关于我们
  {
    path: "/ContactMe",
    name: "ContactMe",
    component: Contact,
  },
];

const router = new VueRouter({
  // 滚动到顶部
  scrollBehavior: () => ({ y: 0 }),
  routes,
});
//防止跳转同一路由报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
