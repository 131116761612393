<template>
  <div class="Airplane">
    <div class="Item" v-show="Show" @click="BackTop()">
      <i class="iconfont icon-zhiding IconfontSizeAirplane"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopPing",
  data() {
    return {
      Show: false,
    };
  },
  methods: {
    BackTop: function () {
      var ScrollHeigh =
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop,
        Timer = setInterval(function () {
          if (ScrollHeigh < 0) {
            clearInterval(Timer);
          }
          ScrollHeigh -= 50;
          document.documentElement.scrollTop = ScrollHeigh;
          window.pageYOffset = ScrollHeigh;
        }, 3);
    },
  },
  mounted: function () {
    var That = this;
    window.onscroll = function () {
      if (document.documentElement.scrollTop > 500) {
        That.Show = true;
      }
      if (document.documentElement.scrollTop < 500) {
        That.Show = false;
      }
    };
  },
};
</script>

<style scoped>
/*pc端*/
@media only screen and (min-device-width: 768px) {
  .Airplane {
    position: fixed;
    right: 3rem;
    bottom: 4rem;
    display: inline-block;
    background-color: hsla(0, 0%, 100%, 0.9);
    box-shadow: rgba(0, 0, 0, 0.43) 0px 0px 6px;
  }
}

/*mobile端*/
@media only screen and (max-device-width: 768px) {
  .Airplane {
    display: none;
  }
}

.Item {
  width: 3.3rem;
  height: 3.3rem;
  margin: 0.5rem;
  cursor: pointer;
}

.IconfontSizeAirplane {
  font-size: 3.3rem;
  color: rgba(0, 0, 0, 0.43);
}
</style>
