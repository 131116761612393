import Vue from "vue";
import "element-ui/lib/theme-chalk/index.css";
import 'element-ui/lib/theme-chalk/display.css';

import {
  Row,
  Col,
  Card,
  Progress,
  Carousel,
  CarouselItem,
  Popover,
  Image,
  Timeline,
  TimelineItem,
  Button
  // Dialog
} from "element-ui";
Vue.component(Row.name, Row);
Vue.component(Col.name, Col);
Vue.component(Card.name, Card);
Vue.component(Progress.name, Progress);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Popover.name, Popover);
Vue.component(Image.name, Image);
Vue.component(Timeline.name, Timeline);
Vue.component(TimelineItem.name, TimelineItem);
Vue.component(Button.name, Button);
// Vue.component(Dialog.name, Dialog);
