import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Active: sessionStorage.getItem("Active") || "0", //当前激活的菜单
    OpenMobileMenu: false, //移动端菜单详情伸开、关闭
    // footer组件
    footer: {
      show: false,
    },
  },
  getters: {},
  mutations: {
    ChangeActive: function (State, Value) {
      State.Active = Value;
      sessionStorage.setItem("Active", Value);
    },
    changeFooter: (state, value) => {
      state.footer.show = value;
    },
  },
  actions: {},
  modules: {},
});
